.site-menu-list {
  min-width: 250px;
  text-align: left;
  padding: 10px 30px 0 0;
  margin: 0;
  list-style: none;
  &__item {
    padding: 5px 0 15px 0;
    vertical-align: top;
    &-group {
      padding: 0 0 5px 0;
      &-link {
        font: 18px var(--font);
        color: #0089ff;
        &:hover {
          color: var(--color-surface-text);
        }
        &_state_active {
          color: var(--color-surface-text);
        }
      }
    }
    &-link {
      padding: 5px 0;
      &_state_active {
        color: #fe2100;
        border-bottom: solid 1px #fe2100;
      }
      &-text {
        font: 14px var(--font);
        color: #0089ff;
        text-decoration: none;
        border-bottom: solid 1px #0089ff;
        &:hover {
          color: #fe2100;
          border-bottom: solid 1px #fe2100;
        }
      }
    }
  }
}
