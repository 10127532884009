.page {
  &_type_main-page {
    & .page {
      &__left {
        width: 225px;
        margin: 0 -225px 0 0;
        float: left;
        position: relative;
        z-index: 10;
      }
      &__middle {
        width: 100%;
        float: left;
        position: relative;
        z-index: 5;
        &-inner {
          padding: 0 250px 0 250px;
        }
      }
      &__right {
        width: 225px;
        margin: 0 0 0 -225px;
        float: right;
        position: relative;
      }
    }
  }
}
