.page-menu {
  background: var(--color-surface);
  margin: 0 0 20px 0;
  position: relative;
  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
    &:first-child {
      padding: 0;
    }
    &__item {
      padding: 0;
      margin: 10px 0;
      & > .page-menu-list {
        padding: 0 0 0 15px;
        margin: 0 0 25px 0;
      }
      &-link {
        font: 14px var(--font);
        color: var(--color-surface-text);
        position: relative;
        display: inline-block;
        &:hover {
          color: var(--color-surface-text-hover);
        }
        &_state_current {
          font-weight: bold;
        }
      }
    }
  }
}
