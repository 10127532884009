.nav {
  margin: 15px 0;
  position: relative;
  display: flex;
  &__item {
    position: relative;
    &_state_current {
      @mixin font normal, 12px, 14px, 12px, 14px, var(--font);
    }
    &-link {
      color: var(--color-surface-text);
      @mixin font normal, 12px, 14px, 12px, 14px, var(--font);
      &:hover {
        color: var(--color-surface-text-hover);
      }
    }
  }
}
