.site-menu {
  display: inline-block;
  vertical-align: top;
  &__link {
    text-decoration: none;
    padding: 3px 15px 5px 8px;
    border: solid 1px var(--color-surface-item);
    position: relative;
    display: inline-block;
    z-index: 3;
    cursor: pointer;
    &:hover {
      & .site-menu__link-text {
        color: var(--color-surface-text-hover);
      }
    }
    &_state_active {
      border: solid 1px var(--color-surface-item-hover);
      z-index: 3;
      & .site-menu__link-inner {
        & span {
          &:nth-child(1) {
            left: 2px;
            top: 0;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
          &:nth-child(3) {
            left: 2px;
            top: 11px;
            transform: rotate(-45deg);
          }
        }
      }
    }
    &-inner {
      width: 16px;
      height: 10px;
      position: relative;
      display: inline-block;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      & span {
        width: 100%;
        height: 2px;
        background: var(--color-surface-item-hover);
        position: absolute;
        display: block;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        opacity: 1;
        &:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 5px;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: 10px;
          transform-origin: left center;
        }
      }
    }
    &-text {
      font: 14px var(--font);
      color: var(--color-surface-text);
      margin: 0 0 0 5px;
      position: relative;
      top: 1px;
    }
  }
}
