
.dropzone {
  &-items-list {
    height: 100px;
    padding: 10px 5px;
    border: solid 1px #ccc;
    display: flex;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    & .dropzone-item {
      margin: 0 5px;
      overflow: hidden;
      position: relative;
      &:hover {
        background: #e7e7e7;
      }
    }
  }
}
