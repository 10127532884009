.page-content {
  clear: both;
  &-inner {
    padding: 15px 0;
  }
  &-text {
    width: 100%;
    position: relative;
  }
}
