
.dropzone-item {
  width: 100px;
  min-width: 100px;
  display: block;
  background: #eee;
  &-move {
    width: 100%;
    font: 14px var(--font);
    text-align: center;
    cursor: move;
    padding: 5px 10px;
    background: #ccc;
    box-sizing: border-box;
    &:hover {
      background: #bbb;
    }
  }
  &-tinymce {
    min-height: 80px;
  }
  &-placeholder {
    transform: translate(0, 0) !important;
    opacity: 0.5;
    position: relative !important;
    background: lime;
    left: 0 !important;
    top: 0 !important;
  }
  &_state_in-area {
    width: 100%;
    &:hover {
      background: #e7e7e7;
    }
  }
  &_state_can-drop {
    box-shadow: 0 5px 30px #bbb;
  }
  &_state_dragging {
    width: auto;
    position: absolute;
    &.placeholder {
      width: auto !important;
      position: relative !important;
      background: lime !important;
      left: 0 !important;
      top: 0 !important;
      box-shadow: none;
    }
  }
}
