/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.dropzone-area {
  border: dashed 1px #ccc;
  padding: 10px;
  box-sizing: border-box;
  transition: background-color 0.3s;
  min-height: 120px;
}

.dropzone-area_state_active {
    border: dashed 1px black;
  }

.dropzone-area_state_target {
    background: #29e;
    border: solid 1px #fff;
  }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.dropzone-item {
  width: 100px;
  min-width: 100px;
  display: block;
  background: #eee;
}

.dropzone-item-move {
    width: 100%;
    font: 0.875rem 'Verdana', geneva, sans-serif;
    text-align: center;
    cursor: move;
    padding: 5px 10px;
    background: #ccc;
    box-sizing: border-box;
  }

.dropzone-item-move:hover {
      background: #bbb;
    }

.dropzone-item-tinymce {
    min-height: 80px;
  }

.dropzone-item-placeholder {
    transform: translate(0, 0) !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
    position: relative !important;
    background: lime;
    left: 0 !important;
    top: 0 !important;
  }

.dropzone-item_state_in-area {
    width: 100%;
  }

.dropzone-item_state_in-area:hover {
      background: #e7e7e7;
    }

.dropzone-item_state_can-drop {
    box-shadow: 0 5px 30px #bbb;
  }

.dropzone-item_state_dragging {
    width: auto;
    position: absolute;
  }

.dropzone-item_state_dragging.placeholder {
      width: auto !important;
      position: relative !important;
      background: lime !important;
      left: 0 !important;
      top: 0 !important;
      box-shadow: none;
    }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.dropzone-items-list {
    height: 100px;
    padding: 10px 5px;
    border: solid 1px #ccc;
    display: flex;
    margin: 0 0 10px 0;
    box-sizing: border-box;
  }

.dropzone-items-list .dropzone-item {
      margin: 0 5px;
      overflow: hidden;
      position: relative;
    }

.dropzone-items-list .dropzone-item:hover {
        background: #e7e7e7;
      }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.nav {
  margin: 15px 0;
  position: relative;
  display: flex;
}

.nav__item {
    position: relative;
  }

.nav__item_state_current {
      font-size: calc(0.75rem + 2 * ((100vw - 20rem) / 2240));
      font-weight: normal;
      font-family: 'Verdana', geneva, sans-serif;
      line-height: calc(0.75rem + 2 * ((100vw - 20rem) / 2240));
    }

@media screen and (min-width: 2560px) {

  .nav__item_state_current {
    line-height: 0.875rem;
  }
}

@media screen and (max-width: 320px) {

  .nav__item_state_current {
    line-height: 0.75rem;
  }
}

@media screen and (min-width: 2560px) {

  .nav__item_state_current {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 320px) {

  .nav__item_state_current {
    font-size: 0.75rem;
  }
}

.nav__item-link {
      color: #000;
      font-size: calc(0.75rem + 2 * ((100vw - 20rem) / 2240));
      font-weight: normal;
      font-family: 'Verdana', geneva, sans-serif;
      line-height: calc(0.75rem + 2 * ((100vw - 20rem) / 2240));
    }

@media screen and (min-width: 2560px) {

  .nav__item-link {
    line-height: 0.875rem;
  }
}

@media screen and (max-width: 320px) {

  .nav__item-link {
    line-height: 0.75rem;
  }
}

@media screen and (min-width: 2560px) {

  .nav__item-link {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 320px) {

  .nav__item-link {
    font-size: 0.75rem;
  }
}

.nav__item-link:hover {
        color: #f4a404;
      }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page_type_main-page .page__left {
        width: 225px;
        margin: 0 -225px 0 0;
        float: left;
        position: relative;
        z-index: 10;
      }

.page_type_main-page .page__middle {
        width: 100%;
        float: left;
        position: relative;
        z-index: 5;
      }

.page_type_main-page .page__middle-inner {
          padding: 0 250px 0 250px;
        }

.page_type_main-page .page__right {
        width: 225px;
        margin: 0 0 0 -225px;
        float: right;
        position: relative;
      }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page-button {
  width: 100%;
  text-align: center;
  padding: 35px 0 40px 0;
}


/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page-content {
  clear: both;
}

.page-content-inner {
    padding: 15px 0;
  }

.page-content-text {
    width: 100%;
    position: relative;
  }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page-menu {
  background: #fff;
  margin: 0 0 20px 0;
  position: relative;
}

.page-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

.page-menu-list:first-child {
      padding: 0;
    }

.page-menu-list__item {
      padding: 0;
      margin: 10px 0;
    }

.page-menu-list__item > .page-menu-list {
        padding: 0 0 0 15px;
        margin: 0 0 25px 0;
      }

.page-menu-list__item-link {
        font: 0.875rem 'Verdana', geneva, sans-serif;
        color: #000;
        position: relative;
        display: inline-block;
      }

.page-menu-list__item-link:hover {
          color: #f4a404;
        }

.page-menu-list__item-link_state_current {
          font-weight: bold;
        }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page-title {
  font: 1.25rem 'Verdana', geneva, sans-serif;
  color: #000;
  text-transform: uppercase;
  padding: 0 0 8px 0;
}

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.page {
  width: 100%;
  background: #fff;
  padding: 35px 0 40px 0;
}

.page__inner {
    width: 100%;
  }

.page__left {
    width: 225px;
    margin: 0 -225px 0 0;
    float: left;
    position: relative;
    z-index: 10;
  }

.page__left-inner {
      width: auto;
    }

.page__right {
    width: 100%;
    float: left;
    position: relative;
    z-index: 5;
  }

.page__right-inner {
      padding: 0 0 0 280px;
    }

.page__middle {
    width: auto;
  }

.page__middle-inner {
      padding: 0;
    }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

#tree .jqtree-title {
    outline: 0;
  }

#tree ul.jqtree-tree .jqtree-element_state_hidden .jqtree-title {
        color: #777;
      }

#tree ul.jqtree-tree li.jqtree-selected > .jqtree-element_state_hidden.jqtree-element .jqtree-title {
      color: #eee;
    }

.dropdown-menu {
  padding: 0;
  margin: 0;
  border-radius: 4px;
  position: absolute;
  display: none;
  box-shadow: 0 10px 50px rgba(65, 118, 144, 0.5);
  left: 0;
  top: 0;
  z-index: 1000;
  list-style: none;
  background-color: #fff;
}

.dropdown-menu__item {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

.dropdown-menu__item-link {
      font: 1rem 'Verdana', geneva, sans-serif;
      color: #333;
      white-space: nowrap;
      padding: 14px 20px 14px 20px;
      display: block;
    }

.dropdown-menu__item-link:hover {
        color: #417690;
        background-color: #f5f5f5;
      }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.site-menu__fader {
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  display: none;
  visibility: hidden;
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.site-menu__fader_state_visible {
    display: block;
    visibility: visible;
    -webkit-animation-name: siteMenuFaderFadeIn;
    animation-name: siteMenuFaderFadeIn;
  }

.site-menu__fader_state_hidden {
    -webkit-animation-name: siteMenuFaderFaderOut;
    animation-name: siteMenuFaderFaderOut;
  }

@-webkit-keyframes siteMenuFaderFadeIn {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
  }
}

@keyframes siteMenuFaderFadeIn {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
  }
}

@-webkit-keyframes siteMenuFaderFaderOut {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
}

@keyframes siteMenuFaderFaderOut {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
}

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.site-menu-list {
  min-width: 250px;
  text-align: left;
  padding: 10px 30px 0 0;
  margin: 0;
  list-style: none;
}

.site-menu-list__item {
    padding: 5px 0 15px 0;
    vertical-align: top;
  }

.site-menu-list__item-group {
      padding: 0 0 5px 0;
    }

.site-menu-list__item-group-link {
        font: 1.125rem 'Verdana', geneva, sans-serif;
        color: #0089ff;
      }

.site-menu-list__item-group-link:hover {
          color: #000;
        }

.site-menu-list__item-group-link_state_active {
          color: #000;
        }

.site-menu-list__item-link {
      padding: 5px 0;
    }

.site-menu-list__item-link_state_active {
        color: #fe2100;
        border-bottom: solid 1px #fe2100;
      }

.site-menu-list__item-link-text {
        font: 0.875rem 'Verdana', geneva, sans-serif;
        color: #0089ff;
        text-decoration: none;
        border-bottom: solid 1px #0089ff;
      }

.site-menu-list__item-link-text:hover {
          color: #fe2100;
          border-bottom: solid 1px #fe2100;
        }

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.site-menu-popup {
  position: absolute;
  display: table;
  visibility: hidden;
  left: 0;
  top: 0;
  z-index: 2;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.site-menu-popup__column {
    padding: 45px 0 20px 10px;
    display: table-cell;
  }

.site-menu-popup_state_visible {
    display: block;
    visibility: visible;
    -webkit-animation-name: siteMenuFadeIn;
    animation-name: siteMenuFadeIn;
  }

.site-menu-popup_state_hidden {
    -webkit-animation-name: siteMenuFaderOut;
    animation-name: siteMenuFaderOut;
  }

@-webkit-keyframes siteMenuFadeIn {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
  }
}

@keyframes siteMenuFadeIn {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
  }
}

@-webkit-keyframes siteMenuFaderOut {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
}

@keyframes siteMenuFaderOut {
  from {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
  }
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
  }
}

/* smartphones, touchscreens */

/* stylus-based screens */

/* Nintendo Wii controller, Microsoft Kinect */

/* mouse, touch pad */

/* stylelint-disable custom-property-empty-line-before */

.site-menu {
  display: inline-block;
  vertical-align: top;
}

.site-menu__link {
    text-decoration: none;
    padding: 3px 15px 5px 8px;
    border: solid 1px #000;
    position: relative;
    display: inline-block;
    z-index: 3;
    cursor: pointer;
  }

.site-menu__link:hover .site-menu__link-text {
        color: #f4a404;
      }

.site-menu__link_state_active {
      border: solid 1px #f4a404;
      z-index: 3;
    }

.site-menu__link_state_active .site-menu__link-inner span:nth-child(1) {
            left: 2px;
            top: 0;
            transform: rotate(45deg);
          }

.site-menu__link_state_active .site-menu__link-inner span:nth-child(2) {
            width: 0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            opacity: 0;
          }

.site-menu__link_state_active .site-menu__link-inner span:nth-child(3) {
            left: 2px;
            top: 11px;
            transform: rotate(-45deg);
          }

.site-menu__link-inner {
      width: 16px;
      height: 10px;
      position: relative;
      display: inline-block;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

.site-menu__link-inner span {
        width: 100%;
        height: 2px;
        background: #f4a404;
        position: absolute;
        display: block;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        opacity: 1;
      }

.site-menu__link-inner span:nth-child(1) {
          top: 0;
          transform-origin: left center;
        }

.site-menu__link-inner span:nth-child(2) {
          top: 5px;
          transform-origin: left center;
        }

.site-menu__link-inner span:nth-child(3) {
          top: 10px;
          transform-origin: left center;
        }

.site-menu__link-text {
      font: 0.875rem 'Verdana', geneva, sans-serif;
      color: #000;
      margin: 0 0 0 5px;
      position: relative;
      top: 1px;
    }
