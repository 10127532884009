
.dropzone-area {
  border: dashed 1px #ccc;
  padding: 10px;
  box-sizing: border-box;
  transition: background-color 0.3s;
  min-height: 120px;
  &_state_active {
    border: dashed 1px black;
  }
  &_state_target {
    background: #29e;
    border: solid 1px #fff;
  }
}
