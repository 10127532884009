#tree {
  & .jqtree-title {
    outline: 0;
  }
  & ul.jqtree-tree {
    & .jqtree-element_state_hidden {
      & .jqtree-title {
        color: #777;
      }
    }
    & li.jqtree-selected > .jqtree-element_state_hidden.jqtree-element .jqtree-title {
      color: #eee;
    }
  }
}

.dropdown-menu {
  padding: 0;
  margin: 0;
  border-radius: 4px;
  position: absolute;
  display: none;
  box-shadow: 0 10px 50px rgba(65, 118, 144, 0.5);
  left: 0;
  top: 0;
  z-index: 1000;
  list-style: none;
  background-color: #fff;
  &__item {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    &-link {
      font: 16px var(--font);
      color: #333;
      white-space: nowrap;
      padding: 14px 20px 14px 20px;
      display: block;
      &:hover {
        color: #417690;
        background-color: #f5f5f5;
      }
    }
  }
}
