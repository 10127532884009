.page {
  width: 100%;
  background: var(--color-surface);
  padding: 35px 0 40px 0;
  &__inner {
    width: 100%;
  }
  &__left {
    width: 225px;
    margin: 0 -225px 0 0;
    float: left;
    position: relative;
    z-index: 10;
    &-inner {
      width: auto;
    }
  }
  &__right {
    width: 100%;
    float: left;
    position: relative;
    z-index: 5;
    &-inner {
      padding: 0 0 0 280px;
    }
  }
  &__middle {
    width: auto;
    &-inner {
      padding: 0;
    }
  }
}
