.site-menu-popup {
  position: absolute;
  display: table;
  visibility: hidden;
  left: 0;
  top: 0;
  z-index: 2;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  opacity: 0;
  &__column {
    padding: 45px 0 20px 10px;
    display: table-cell;
  }
  &_state_visible {
    display: block;
    visibility: visible;
    animation-name: siteMenuFadeIn;
  }
  &_state_hidden {
    animation-name: siteMenuFaderOut;
  }
}

@keyframes siteMenuFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes siteMenuFaderOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
