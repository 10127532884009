.site-menu__fader {
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  display: none;
  visibility: hidden;
  left: 0;
  top: 0;
  z-index: 1;
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
  &_state_visible {
    display: block;
    visibility: visible;
    animation-name: siteMenuFaderFadeIn;
  }
  &_state_hidden {
    animation-name: siteMenuFaderFaderOut;
  }
}

@keyframes siteMenuFaderFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes siteMenuFaderFaderOut {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}
