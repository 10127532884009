/* stylelint-disable custom-property-empty-line-before */

:root {
  --font: 'Verdana', geneva, sans-serif;
  --font-title: 'PT Sans Caption', sans-serif;
  --font-main-page: 'Montserrat', sans-serif;

  --font-email: 'Montserrat', Verdana, sans-serif;
  --font-secondary: #bbb;

  --font-pdf: 'Times New Roman', serif;
  --font-pdf-desc: 'Verdana', geneva, sans-serif;

  --color-primary: #002963;
  --color-primary-hover: color(rgba(#002963, 1) l(30%));
  --color-primary-text: #fff;
  --color-primary-text-hover: #fff;
  --color-primary-text-hover-accent: #f4a404;
  --color-primary-item: #fff;

  --color-secondary: #eaeaea;
  --color-secondary-hover: #e7e7e7;
  --color-secondary-text: #000;
  --color-secondary-text-hover: #f4a404;
  --color-secondary-border: #eaeaea;

  --color-neutral: #efefef;
  --color-neutral-hover: #000;
  --color-neutral-text: #000;
  --color-neutral-text-hover: #f4a404;

  --color-surface: #fff;
  --color-surface-hover: #fafafa;
  --color-surface-text: #000;
  --color-surface-text-hover: #f4a404;
  --color-surface-item: #000;
  --color-surface-item-hover: #f4a404;
  --color-surface-border: #ccc;

  --color-accent: #f4a404;
  --color-accent-hover: color(rgba(#f4a404, 1) l(30%));
  --color-accent-text: #fff;
  --color-accent-text-hover: #fff;
  --color-accent-item: #fff;
  --color-accent-border: #f4a404;

  --color-error: red;
  --color-error-text: #fff;
  --color-error-bg: rgba(#f00, 0.05);

  --color-success: #00b159;
  --color-success-text: #fff;
  --color-success-bg: rgba(#00b159, 0.05);

  --color-form-border: #bbb;
  --color-form-border-hover: #ccc;
  --color-form-border-error: red;

  --color-form-bg: #fff;
  --color-form-bg-hover: #eaeaea;
  --color-form-bg-active: #ebebeb;

  --color-form-disabled-bg: #fafafa;
  --color-form-disabled-text: #777;
  --color-form-disabled-border: #b7b7b7;

  --min-max: 320px 1600px;
  --min: 320px;
  --max: 1600px;


  --color-promo: #26008E;

}
